body {
  margin: 0;
  font-family: sans-serif;
}

.dropzone {
  box-sizing: border-box;
  z-index: 99999;
  background: rgba(#60a7dc, .8);
  border: 11px dashed #60a7dc;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=index.cb45ed45.css.map */
