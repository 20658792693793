body {
  font-family: sans-serif;
  margin: 0;
}
.dropzone {
	box-sizing: border-box;
	display: none;
	position: fixed;
	width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
	background: rgba(#60a7dc,.8);
	border: 11px dashed #60a7dc;
}